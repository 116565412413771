<template>
  <span v-if="max == 1"></span>
  <span v-else-if="number == 0">&#9312;</span>
  <span v-else-if="number == 1">&#9313;</span>
  <span v-else-if="number == 2">&#9314;</span>
  <span v-else-if="number == 3">&#9315;</span>
  <span v-else-if="number == 4">&#9316;</span>
  <span v-else-if="number == 5">&#9317;</span>
  <span v-else-if="number == 6">&#9318;</span>
  <span v-else-if="number == 7">&#9319;</span>
  <span v-else-if="number == 8">&#9320;</span>
  <span v-else-if="number == 9">&#9321;</span>
  <span v-else-if="number == 10">&#9322;</span>
  <span v-else-if="number == 11">&#9323;</span>
  <span v-else-if="number == 12">&#9324;</span>
  <span v-else-if="number == 13">&#9325;</span>
  <span v-else-if="number == 14">&#9326;</span>
  <span v-else-if="number == 15">&#9327;</span>
  <span v-else-if="number == 16">&#9328;</span>
  <span v-else-if="number == 17">&#9329;</span>
  <span v-else-if="number == 18">&#9330;</span>
  <span v-else-if="number == 19">&#9331;</span>
  <span v-else-if="number == 20">㉑</span>
  <span v-else-if="number == 21">㉒</span>
  <span v-else-if="number == 22">㉓</span>
  <span v-else-if="number == 23">㉔</span>
  <span v-else-if="number == 24">㉕</span>
  <span v-else-if="number == 25">㉖</span>
  <span v-else-if="number == 26">㉗</span>
  <span v-else-if="number == 27">㉘</span>
  <span v-else-if="number == 28">㉙</span>
  <span v-else-if="number == 29">㉚</span>
  <span v-else-if="number == 30">㉛</span>
  <span v-else-if="number == 31">㉜</span>
  <span v-else></span>
</template>

<script>
export default {
  name: "sort",
  components: {},
  props: {
    max: {
      type: Number,
      default: () => 0,
    },
    number: {
      type: Number,
      default: () => 0,
    },
  },
  methods: {},
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
</style>