import axios from '@/plugins/axios';

class TrainingRecordApi {
  static getRecord(
    page = 1,
    limit = 10,
    channelId = NaN,
    storeId = NaN,
    productNo = '',
    produceNo = '',
    startDate = '',
    endDate = '',
    startDates = '',
    endDates = '',
    customerNickName = '',
    sgsNickName = '',
    sex = NaN,
    customerMobile = '',
    patientType = NaN,
    minAge = NaN,
    maxAge = NaN,
    name = '',
    productType = NaN,
    activeMethod = '',
  ) {
    return axios({
      url: '/api/train/record/page',
      data: {
        page,
        limit,
        channel_id: channelId,
        store_id: storeId,
        product_no: productNo,
        produce_no: produceNo,
        start_date: startDate,
        end_date: endDate,
        start_dates: startDates,
        end_dates: endDates,
        customer_nick_name: customerNickName,
        sgs_nick_name: sgsNickName,
        sex,
        customer_mobile: customerMobile,
        patient_type: patientType,
        min_age: minAge,
        max_age: maxAge,
        train_process: name,
        product_type: productType,
        active_method: activeMethod,
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询训练记录列表',
        },
      },
    });
  }

  static export(data) {
    return axios({
      url: '/api/train/record/export',
      method: 'post',
      data,
      headers: {
        meta: {
          skipSuccess: true,
          desc: '导出',
        },
      },
    });
  }
}

export default TrainingRecordApi;
