import DataTransferUtil from '@/utils/data-transfer.util';

function renderHeader(h, { column }) {
  return h(
    'div',
    [
      h('span', column.title.split('(')[0]),
      h('br'),
      h('span', {
        style: 'color:blue;font-size: 12px;',
      }, column.title.split('(')[1].replace(')', '')),
    ],
  );
}

export const RECORD_COLUMNS = [
  {
    title: '训练开始时间',
    key: 'start_time',
    minWidth: 180,
  },
  {
    title: '训练结束时间',
    key: 'end_time',
    minWidth: 180,
  },
  {
    title: '用户名',
    key: 'customer_nick_name',
    minWidth: 140,
    fixed: 'left',
  },
  {
    title: '手机号',
    key: 'customer_mobile',
    minWidth: 130,
  },
  {
    title: '性别',
    key: 'sex',
    slot: 'sex',
    minWidth: 60,
  },
  {
    title: '年龄',
    key: 'age',
    minWidth: 60,
  },
  {
    title: '患者类型',
    key: 'patient_type',
    slot: 'patient_type',
    minWidth: 100,
  },
  {
    title: '渠道',
    key: 'channel_name',
    minWidth: 100,
  },
  {
    title: '门店',
    key: 'store_name',
    minWidth: 100,
  },
  // {
  //   title: '设备编号',
  //   key: 'product_no',
  //   minWidth: 150,
  // },
  {
    title: '生产编号',
    key: 'produce_no',
    minWidth: 150,
  },
  {
    title: '软件版本号',
    key: 'software_version',
    minWidth: 130,
  },
  {
    title: '硬件版本',
    key: 'hardware_version',
    minWidth: 130,
  },
  {
    title: '视光师',
    key: 'sgs_nick_name',
    minWidth: 130,
  },
  {
    title: '调节幅度(通过的最高度数)',
    renderHeader,
    key: '调节幅度训练',
    slot: '调节幅度训练',
    minWidth: 130,
  },
  {
    title: '调节灵活度(镜片通过每分钟周期数)',
    key: '调节灵活度训练',
    slot: '调节灵活度训练',
    renderHeader,
    minWidth: 220,
  },
  {
    title: '镜片阅读(阅读速度)',
    key: '镜片阅读训练',
    slot: '镜片阅读训练',
    renderHeader,
    minWidth: 150,
  },
  {
    title: '静态融像(集合散开范围)',
    key: '静态融像训练',
    slot: '静态融像训练',
    renderHeader,
    minWidth: 150,
  },
  {
    title: '动态融像(集合散开范围)',
    key: '动态融像训练',
    slot: '动态融像训练',
    renderHeader,
    minWidth: 150,
  },
  {
    title: '融像综合(集合散开范围)',
    key: '融像综合训练',
    slot: '融像综合训练',
    renderHeader,
    minWidth: 180,
  },
  {
    title: '脱抑制红绿阅读(阅读速度)',
    key: '脱抑制训练—红绿阅读',
    slot: '脱抑制训练—红绿阅读',
    renderHeader,
    minWidth: 150,
  },
  {
    title: '脱抑制捕捉(每分钟捕捉)',
    key: '脱抑制训练—捕捉训练',
    slot: '脱抑制训练—捕捉训练',
    renderHeader,
    minWidth: 130,
  },
  {
    title: '红闪漫画(阅读页数)',
    key: '红闪训练—漫画故事',
    slot: '红闪训练—漫画故事',
    renderHeader,
    minWidth: 130,
  },
  {
    title: '红闪视标(通过的最高视标等级)',
    key: '红闪训练—视标模式',
    slot: '红闪训练—视标模式',
    renderHeader,
    minWidth: 130,
  },
  {
    title: '红闪找不同(平均每分钟找出个数)',
    key: '红闪训练—找不同',
    slot: '红闪训练—找不同',
    renderHeader,
    minWidth: 130,
  },
  {
    title: '光栅单词(学习单词个数)',
    key: '光栅训练—单词模式',
    slot: '光栅训练—单词模式',
    renderHeader,
    minWidth: 130,
  },
  {
    title: '光栅视标(通过的最高视标等级)',
    key: '光栅训练—视标模式',
    slot: '光栅训练—视标模式',
    renderHeader,
    minWidth: 130,
  },
  {
    title: '光栅色图(平均每分钟正确辨别)',
    key: '光栅训练—色图模式',
    slot: '光栅训练—色图模式',
    renderHeader,
    minWidth: 130,
  },
  {
    title: '精细目力捕鱼(单位时间数量)',
    key: '精细目力训练—捕鱼训练',
    slot: '精细目力训练—捕鱼训练',
    renderHeader,
    minWidth: 130,
  },
  {
    title: '精细目力串珠(单位时间数量)',
    key: '精细目力训练—串珠训练',
    slot: '精细目力训练—串珠训练',
    renderHeader,
    minWidth: 130,
  },
  {
    title: '眼肌运动(40cm处追随速度)',
    renderHeader,
    key: '眼肌运动训练',
    slot: '眼肌运动训练',
    minWidth: 130,
  },
  {
    title: '视觉专注追随(平均每分钟辨别个数)',
    key: '视觉专注训练—追随训练',
    slot: '视觉专注训练—追随训练',
    renderHeader,
    minWidth: 130,
  },
  {
    title: '视觉专注反差阅读(阅读页数)',
    key: '视觉专注训练—反差阅读',
    slot: '视觉专注训练—反差阅读',
    renderHeader,
    minWidth: 130,
  },
  {
    title: '视觉专注找不同(平均每分钟找出个数)',
    key: '视觉专注训练—找不同',
    slot: '视觉专注训练—找不同',
    renderHeader,
    minWidth: 130,
  },
  {
    title: '扫视训练视标模式(扫视视标个数)',
    key: '扫视训练—视标模式',
    slot: '扫视训练—视标模式',
    renderHeader,
    minWidth: 130,
  },
  {
    title: '扫视训练阅读速度(最快阅读速度)',
    key: '扫视训练—阅读速度',
    slot: '扫视训练—阅读速度',
    renderHeader,
    minWidth: 130,
  },
  {
    title: '扫视训练文章阅读(阅读速度)',
    key: '扫视训练—文章阅读',
    slot: '扫视训练—文章阅读',
    renderHeader,
    minWidth: 130,
  },
];

/* 性别 */
export const EXPORT_SEX_OP = {
  0: '女',
  1: '男',
};

export const EXPORT_SEX_OPTIONS = DataTransferUtil.enumTransfer2Options(EXPORT_SEX_OP);

/* 患者类型 */
export const EXPORT_TYPE_OP = {
  0: '尚未近视',
  1: '弱视',
  2: '近视',
  3: '其他',
  4: '未分类',
};

export const EXPORT_TYPE_OPTIONS = DataTransferUtil.enumTransfer2Options(EXPORT_TYPE_OP);

/* 患者类型 */
export const EXPORT_TYPE_OPE = {
  0: '未分类',
  1: '个人用户',
  2: '门店用户',
};

export const EXPORT_TYPE_OPTIONSE = DataTransferUtil.enumTransfer2Options(EXPORT_TYPE_OPE);

/* 训练项目 */
export const EXPORT_NAME_OPE = {
  调节幅度训练: '调节幅度训练',
  调节灵活度训练: '调节灵活度训练',
  镜片阅读训练: '镜片阅读训练',
  静态融像训练: '静态融像训练',
  动态融像训练: '动态融像训练',
  融像综合训练: '融像综合训练',
  红绿阅读: '脱抑制训练—红绿阅读',
  捕捉训练: '脱抑制训练—捕捉训练',
  漫画故事: '红闪训练—漫画故事',
  视标模式: '视标模式',
  找不同: '找不同',
  单词模式: '光栅训练—单词模式',
  视标模式: '光栅训练—视标模式',
  色图模式: '光栅训练—色图模式',
  捕鱼训练: '精细目力训练—捕鱼训练',
  串珠训练: '精细目力训练—串珠训练',
  眼肌运动训练: '眼肌运动训练',
  追随训练: '视觉专注训练—追随训练',
  反差阅读: '视觉专注训练—反差阅读',
  阅读速度: '扫视训练—阅读速度',
  文章阅读: '扫视训练—文章阅读',
};

export const EXPORT_NAME_OPTIONSE = DataTransferUtil.enumTransfer2Options(EXPORT_NAME_OPE);
