<template>
  <div class="training-record">
    <app-block-flex>
      <app-query-bar
        :queryConfig="queryConfig"
        v-model="queryData"
        @onSearch="fetchRecords"
      >
        <template slot="ageRange">
          <app-spacing class="age-range" spacing="sm">
            <div>
              <InputNumber :max="150" :min="0" v-model="queryData.minAge" />
            </div>
            <div>至</div>
            <div>
              <InputNumber :max="150" :min="0" v-model="queryData.maxAge" />
            </div>
          </app-spacing>
        </template>
        <template slot="startDate">
          <app-spacing class="age-range" spacing="sm">
            <div>
              <el-date-picker
                type="date"
                placement="bottom-end"
                :style="{ width: '160px' }"
                :placeholder="`请选择时间`"
                v-model="queryData.startDate"
                value-format="yyyy-MM-dd"
              />
            </div>
            <div>至</div>
            <div>
              <el-date-picker
                type="date"
                placement="bottom-end"
                :style="{ width: '160px' }"
                :placeholder="`请选择时间`"
                v-model="queryData.endDate"
                value-format="yyyy-MM-dd"
              />
            </div>
          </app-spacing>
        </template>
        <template slot="endDate">
          <app-spacing class="age-range" spacing="sm">
            <div>
              <el-date-picker
                type="date"
                placement="bottom-end"
                :placeholder="`请选择时间`"
                :style="{ width: '160px' }"
                v-model="queryData.startDates"
                value-format="yyyy-MM-dd"
              />
            </div>
            <div>至</div>
            <div>
              <el-date-picker
                type="date"
                placement="bottom-end"
                :placeholder="`请选择时间`"
                :style="{ width: '160px' }"
                v-model="queryData.endDates"
                value-format="yyyy-MM-dd"
              />
            </div>
          </app-spacing>
        </template>
        <template slot="channelId">
          <app-spacing class="age-range" spacing="sm">
            <div v-if="this.userType !== 'OUTLET'">
              <el-select
                size="small"
                filterable
                clearable
                :value="queryData.channelId"
                :placeholder="`请选择渠道`"
                @change="(value) => onChange(value)"
              >
                <el-option
                  v-for="option in queryConfigChannel"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id"
                />
              </el-select>
            </div>
          </app-spacing>
        </template>
      </app-query-bar>
    </app-block-flex>
    <app-export-bar @onExport="onExport" @onExportAll="onExportAll" />
    <app-data-table
      :columns="RECORD_COLUMNS"
      style="overflow-x: scroll"
      :data="records"
      v-model="queryData"
      @onPageConfigChange="fetchRecords"
    >
      <template slot-scope="{ row }" slot="sex">
        {{ EXPORT_SEX_OP[row.sex] }}
      </template>
      <template slot-scope="{ row }" slot="patient_type">
        {{ EXPORT_TYPE_OP[row.patient_type] }}
      </template>
      <template slot-scope="{ row }" slot="调节幅度训练">
        <div v-if="getRowData(row.record, '调节幅度训练').length == 0">---</div>
        <div v-else>
          <div
            v-for="(e, index) in getRowData(row.record, '调节幅度训练', row.hardware_version)"
            :key="index"
          >
            <sort
              :max="getRowData(row.record, '调节幅度训练').length"
              :number="index"
            />
            {{
              e.state == "2"
                ? "跳过"
                : e.state == "3"
                ? "未正常结束"
                : e.最高度数 || 0
            }}
          </div>
        </div>
      </template>
      <template slot-scope="{ row }" slot="调节灵活度训练">
        <div v-if="getRowData(row.record, '调节灵活度训练').length == 0">
          ---
        </div>
        <div v-else>
          <div
            v-for="(e, index) in getRowData(row.record, '调节灵活度训练', row.hardware_version)"
            :key="index"
          >
            <sort
              :max="getRowData(row.record, '调节灵活度训练').length"
              :number="index"
            />
            {{
              e.state == "2"
                ? "跳过"
                : e.state == "3"
                ? "未正常结束"
                : e.训练类型 == '情景' ? '--' : convertNewHardwareData(row, '调节灵活度训练', "±1.50cpm:" +
                  (e.周期数 || '--') +
                  ";" +
                  "±2.50cpm:" +
                  (e.周期数250 || '--'))
            }}
          </div>
        </div>
      </template>
      <template slot-scope="{ row }" slot="镜片阅读训练">
        <div v-if="getRowData(row.record, '镜片阅读训练').length == 0">---</div>
        <div v-else>
          <div
            v-for="(e, index) in getRowData(row.record, '镜片阅读训练')"
            :key="index"
          >
            <sort
              :max="getRowData(row.record, '镜片阅读训练').length"
              :number="index"
            />
            {{
              e.state == "2"
                ? "跳过"
                : e.state == "3"
                ? "未正常结束"
                : e.镜片类型.slice(0, 2) + "_" + (e.阅读速度 || 0) + "个/min"
            }}
          </div>
        </div>
      </template>
      <template slot-scope="{ row }" slot="静态融像训练">
        <div v-if="getRowData(row.record, '静态融像训练').length == 0">---</div>
        <div v-else>
          <div
            v-for="(e, index) in getRowData(row.record, '静态融像训练')"
            :key="index"
          >
            <sort
              :max="getRowData(row.record, '静态融像训练').length"
              :number="index"
            />
            {{
              e.state == "2"
                ? "跳过"
                : e.state == "3"
                ? "未正常结束"
                : `${e.集合范围 ? "集合" : "散开"}${
                    e.集合范围 || e.散开范围 || 0
                  }▲ `
            }}
          </div>
        </div>
      </template>
      <template slot-scope="{ row }" slot="动态融像训练">
        <div v-if="getRowData(row.record, '动态融像训练').length == 0">---</div>
        <div v-else>
          <div
            v-for="(e, index) in getRowData(row.record, '动态融像训练')"
            :key="index"
          >
            <sort
              :max="getRowData(row.record, '动态融像训练').length"
              :number="index"
            />
            {{
              e.state == "2"
                ? "跳过"
                : e.state == "3"
                ? "未正常结束"
                : `${e.集合范围 ? "集合" : "散开"}${
                    e.集合范围 || e.散开范围 || 0
                  }▲ `
            }}
          </div>
        </div>
      </template>
      <template slot-scope="{ row }" slot="融像综合训练">
        <div v-if="getRowData(row.record, '融像综合训练').length == 0">---</div>
        <div v-else>
          <div
            v-for="(e, index) in getRowData(row.record, '融像综合训练')"
            :key="index"
          >
            <sort
              :max="getRowData(row.record, '融像综合训练').length"
              :number="index"
            />
            {{
              e.state == "2"
                ? "跳过"
                : e.state == "3"
                ? "未正常结束"
                : `${e.散开范围 || 0}▲ ~ +${e.集合范围 || 0}▲ `
            }}
          </div>
        </div>
      </template>
      <template slot-scope="{ row }" slot="脱抑制训练—红绿阅读">
        <div v-if="getRowData(row.record, '脱抑制训练—红绿阅读').length == 0">
          ---
        </div>
        <div v-else>
          <div
            v-for="(e, index) in getRowData(row.record, '脱抑制训练—红绿阅读')"
            :key="index"
          >
            <sort
              :max="getRowData(row.record, '脱抑制训练—红绿阅读').length"
              :number="index"
            />
            {{
              e.state == "2"
                ? "跳过"
                : e.state == "3"
                ? "未正常结束"
                : `左眼${e.左眼速度 || 0}字/s, 右眼${e.右眼速度 || 0}字/s `
            }}
          </div>
        </div>
      </template>
      <template slot-scope="{ row }" slot="脱抑制训练—捕捉训练">
        <div v-if="getRowData(row.record, '脱抑制训练—捕捉训练').length == 0">
          ---
        </div>
        <div v-else>
          <div
            v-for="(e, index) in getRowData(row.record, '脱抑制训练—捕捉训练')"
            :key="index"
          >
            <sort
              :max="getRowData(row.record, '脱抑制训练—捕捉训练').length"
              :number="index"
            />
            {{
              e.state == "2"
                ? "跳过"
                : e.state == "3"
                ? "未正常结束"
                : (e.捕捉数 || 0) + "个/min"
            }}
          </div>
        </div>
      </template>
      <template slot-scope="{ row }" slot="红闪训练—漫画故事">
        <div v-if="getRowData(row.record, '红闪训练—漫画故事').length == 0">
          ---
        </div>
        <div v-else>
          <div
            v-for="(e, index) in getRowData(row.record, '红闪训练—漫画故事')"
            :key="index"
          >
            <sort
              :max="getRowData(row.record, '红闪训练—漫画故事').length"
              :number="index"
            />
            {{
              e.state == "2"
                ? "跳过"
                : e.state == "3"
                ? "未正常结束"
                : (e.阅读页数 || 0) + "页"
            }}
          </div>
        </div>
      </template>
      <template slot-scope="{ row }" slot="红闪训练—视标模式">
        <div v-if="getRowData(row.record, '红闪训练—视标模式').length == 0">
          ---
        </div>
        <div v-else>
          <div
            v-for="(e, index) in getRowData(row.record, '红闪训练—视标模式')"
            :key="index"
          >
            <sort
              :max="getRowData(row.record, '红闪训练—视标模式').length"
              :number="index"
            />
            {{
              e.state == "2"
                ? "跳过"
                : e.state == "3"
                ? "未正常结束"
                : e.视标最高等级 || "---"
            }}
          </div>
        </div>
      </template>
      <template slot-scope="{ row }" slot="红闪训练—找不同">
        <div v-if="getRowData(row.record, '红闪训练—找不同').length == 0">
          ---
        </div>
        <div v-else>
          <div
            v-for="(e, index) in getRowData(row.record, '红闪训练—找不同')"
            :key="index"
          >
            <sort
              :max="getRowData(row.record, '红闪训练—找不同').length"
              :number="index"
            />
            {{
              e.state == "2"
                ? "跳过"
                : e.state == "3"
                ? "未正常结束"
                : (e.找到个数 || 0) + "个/min"
            }}
          </div>
        </div>
      </template>
      <template slot-scope="{ row }" slot="光栅训练—单词模式">
        <div v-if="getRowData(row.record, '光栅训练—单词模式').length == 0">
          ---
        </div>
        <div v-else>
          <div
            v-for="(e, index) in getRowData(row.record, '光栅训练—单词模式')"
            :key="index"
          >
            <sort
              :max="getRowData(row.record, '光栅训练—单词模式').length"
              :number="index"
            />
            {{
              e.state == "2"
                ? "跳过"
                : e.state == "3"
                ? "未正常结束"
                : (e.单词个数 || 0) + "个"
            }}
          </div>
        </div>
      </template>
      <template slot-scope="{ row }" slot="光栅训练—视标模式">
        <div v-if="getRowData(row.record, '光栅训练—视标模式').length == 0">
          ---
        </div>
        <div v-else>
          <div
            v-for="(e, index) in getRowData(row.record, '光栅训练—视标模式')"
            :key="index"
          >
            <sort
              :max="getRowData(row.record, '光栅训练—视标模式').length"
              :number="index"
            />
            {{
              e.state == "2"
                ? "跳过"
                : e.state == "3"
                ? "未正常结束"
                : e.视标最高等级 || "---"
            }}
          </div>
        </div>
      </template>
      <template slot-scope="{ row }" slot="光栅训练—色图模式">
        <div v-if="getRowData(row.record, '光栅训练—色图模式').length == 0">
          ---
        </div>
        <div v-else>
          <div
            v-for="(e, index) in getRowData(row.record, '光栅训练—色图模式')"
            :key="index"
          >
            <sort
              :max="getRowData(row.record, '光栅训练—色图模式').length"
              :number="index"
            />
            {{
              e.state == "2"
                ? "跳过"
                : e.state == "3"
                ? "未正常结束"
                : (e.辨别个数 || 0) + "个/min"
            }}
          </div>
        </div>
      </template>
      <template slot-scope="{ row }" slot="精细目力训练—捕鱼训练">
        <div v-if="getRowData(row.record, '精细目力训练—捕鱼训练').length == 0">
          ---
        </div>
        <div v-else>
          <div
            v-for="(e, index) in getRowData(
              row.record,
              '精细目力训练—捕鱼训练'
            )"
            :key="index"
          >
            <sort
              :max="getRowData(row.record, '精细目力训练—捕鱼训练').length"
              :number="index"
            />
            {{
              e.state == "2"
                ? "跳过"
                : e.state == "3"
                ? "未正常结束"
                : (e.捕捉个数 || 0) + "个/min"
            }}
          </div>
        </div>
      </template>
      <template slot-scope="{ row }" slot="精细目力训练—串珠训练">
        <div v-if="getRowData(row.record, '精细目力训练—串珠训练').length == 0">
          ---
        </div>
        <div v-else>
          <div
            v-for="(e, index) in getRowData(
              row.record,
              '精细目力训练—串珠训练'
            )"
            :key="index"
          >
            <sort
              :max="getRowData(row.record, '精细目力训练—串珠训练').length"
              :number="index"
            />
            {{
              e.state == "2"
                ? "跳过"
                : e.state == "3"
                ? "未正常结束"
                : (e.串珠个数 || 0) + "个/min"
            }}
          </div>
        </div>
      </template>
      <template slot-scope="{ row }" slot="眼肌运动训练">
        <div v-if="getRowData(row.record, '眼肌运动训练').length == 0">---</div>
        <div v-else>
          <div
            v-for="(e, index) in getRowData(row.record, '眼肌运动训练')"
            :key="index"
          >
            <sort
              :max="getRowData(row.record, '眼肌运动训练').length"
              :number="index"
            />
            {{
              e.state == "2"
                ? "跳过"
                : e.state == "3"
                ? "未正常结束"
                : (e.追随速度 || 0) + "cm/s"
            }}
          </div>
        </div>
      </template>
      <template slot-scope="{ row }" slot="视觉专注训练—追随训练">
        <div v-if="getRowData(row.record, '视觉专注训练—追随训练').length == 0">
          ---
        </div>
        <div v-else>
          <div
            v-for="(e, index) in getRowData(
              row.record,
              '视觉专注训练—追随训练'
            )"
            :key="index"
          >
            <sort
              :max="getRowData(row.record, '视觉专注训练—追随训练').length"
              :number="index"
            />
            {{
              e.state == "2"
                ? "跳过"
                : e.state == "3"
                ? "未正常结束"
                : e.训练类型 == "引导追随"
                ? "完成"
                : typeof e.辨别个数 === 'string'  && e.辨别个数.indexOf('1级') > -1
                  ? e.辨别个数
                  :(e.辨别个数 || 0) + (e.等级 == "高级" ? "个" : "个/min")
            }}
          </div>
        </div>
      </template>
      <template slot-scope="{ row }" slot="视觉专注训练—反差阅读">
        <div v-if="getRowData(row.record, '视觉专注训练—反差阅读').length == 0">
          ---
        </div>
        <div v-else>
          <div
            v-for="(e, index) in getRowData(
              row.record,
              '视觉专注训练—反差阅读'
            )"
            :key="index"
          >
            <sort
              :max="getRowData(row.record, '视觉专注训练—反差阅读').length"
              :number="index"
            />
            {{
              e.state == "2"
                ? "跳过"
                : e.state == "3"
                ? "未正常结束"
                : e.阅读页数 + "页"
            }}
          </div>
        </div>
      </template>
      <template slot-scope="{ row }" slot="视觉专注训练—找不同">
        <div v-if="getRowData(row.record, '视觉专注训练—找不同').length == 0">
          ---
        </div>
        <div v-else>
          <div
            v-for="(e, index) in getRowData(row.record, '视觉专注训练—找不同')"
            :key="index"
          >
            <sort
              :max="getRowData(row.record, '视觉专注训练—找不同').length"
              :number="index"
            />
            {{
              e.state == "2"
                ? "跳过"
                : e.state == "3"
                ? "未正常结束"
                : (e.找到个数 || 0) + "个/min"
            }}
          </div>
        </div>
      </template>
      <template slot-scope="{ row }" slot="扫视训练—视标模式">
        <div v-if="getRowData(row.record, '扫视训练—视标模式').length == 0">
          ---
        </div>
        <div v-else>
          <div
            v-for="(e, index) in getRowData(row.record, '扫视训练—视标模式')"
            :key="index"
          >
            <sort
              :max="getRowData(row.record, '扫视训练—视标模式').length"
              :number="index"
            />
            {{
              e.state == "2"
                ? "跳过"
                : e.state == "3"
                ? "未正常结束"
                : (e.阅读个数 || 0) + "个"
            }}
          </div>
        </div>
      </template>
      <template slot-scope="{ row }" slot="扫视训练—阅读速度">
        <div v-if="getRowData(row.record, '扫视训练—阅读速度').length == 0">
          ---
        </div>
        <div v-else>
          <div
            v-for="(e, index) in getRowData(row.record, '扫视训练—阅读速度')"
            :key="index"
          >
            <sort
              :max="getRowData(row.record, '扫视训练—阅读速度').length"
              :number="index"
            />
            {{
              e.state == "2"
                ? "跳过"
                : e.state == "3"
                ? "未正常结束"
                : (e.阅读速度 || 0) + "个字/s"
            }}
          </div>
        </div>
      </template>
      <template slot-scope="{ row }" slot="扫视训练—文章阅读">
        <div v-if="getRowData(row.record, '扫视训练—文章阅读').length == 0">
          ---
        </div>
        <div v-else>
          <div
            v-for="(e, index) in getRowData(row.record, '扫视训练—文章阅读')"
            :key="index"
          >
            <sort
              :max="getRowData(row.record, '扫视训练—文章阅读').length"
              :number="index"
            />
            {{
              e.state == "2"
                ? "跳过"
                : e.state == "3"
                ? "未正常结束"
                : (e.阅读页数 || 0) + "页/min"
            }}
          </div>
        </div>
      </template>
    </app-data-table>
  </div>
</template>

<script>
import { Message } from 'view-design';
import {
  RECORD_COLUMNS,
  EXPORT_SEX_OP,
  EXPORT_TYPE_OP,
  EXPORT_SEX_OPTIONS,
  EXPORT_TYPE_OPTIONS,
  EXPORT_NAME_OPTIONSE,
} from './constants';
import TrainingRecordApi from './training-record.api';
import sort from './sort.component.vue';
import ChannelManageApi from '../../business/channel-manage/channel-manage.api';
import StoreManageApi from '../../business/store-manage/store-manage.api';
import DataTransferUtil from '../../../utils/data-transfer.util';

export default {
  name: 'training-record',
  components: {
    sort,
  },
  computed: {
    userType() {
      return this.$store.getters.userType;
    },
    queryConfig() {
      return [
        {
          fieldName: 'customerNickName',
          name: '用户姓名',
        },
        {
          fieldName: 'customerMobile',
          name: '手机号',
        },
        {
          fieldName: 'channelId',
          name: '渠道',
          // type: 'select',
          show: this.userType === 'OUTLET',
          options: this.queryConfigChannelOptions,
          type: 'slot',
        },
        {
          fieldName: 'storeId',
          name: '门店',
          type: 'select',
          options: this.queryConfigStoreOptions,
        },
        {
          fieldName: 'startDate',
          name: '训练开始时间',
          // type: 'date',
          type: 'slot',
        },
        {
          fieldName: 'endDate',
          name: '训练结束时间',
          // type: 'date',
          type: 'slot',
        },
        // {
        //   fieldName: "productNo",
        //   name: "设备编号",
        // },
        {
          fieldName: 'produceNo',
          name: '生产编号',
        },
        {
          fieldName: 'sex',
          name: '性别',
          type: 'select',
          options: EXPORT_SEX_OPTIONS,
        },
        {
          fieldName: 'ageRange',
          name: '年龄范围',
          type: 'slot',
        },
        {
          fieldName: 'patientType',
          name: '患者类型',
          type: 'select',
          options: EXPORT_TYPE_OPTIONS,
        },
        {
          fieldName: 'sgsNickName',
          name: '视光师',
        },
        {
          fieldName: 'name',
          name: '训练项目',
          type: 'select',
          options: EXPORT_NAME_OPTIONSE,
        },
        {
          fieldName: 'productType',
          name: '设备类型',
          type: 'select',
          options: this.queryConfigProductTypeOptions,
        },
        {
          fieldName: 'activeMethod',
          name: '激活方式',
          type: 'select',
          options: this.queryConfigProductActivaMethodOptions,
        },
      ];
    },
  },
  data() {
    return {
      RECORD_COLUMNS,
      EXPORT_SEX_OP,
      EXPORT_TYPE_OP,
      EXPORT_SEX_OPTIONS,
      EXPORT_TYPE_OPTIONS,
      EXPORT_NAME_OPTIONSE,
      queryData: {
        page: 1,
        limit: 10,
        count: 0,
        channelId: '',
        storeId: '',
        productNo: '',
        produceNo: '',
        customerNickName: '',
        sgsNickName: '',
        startDate: '',
        endDate: '',
        startDates: '',
        endDates: '',
        sex: '',
        patientType: '',
        minAge: null,
        maxAge: null,
        customerMobile: '',
        name: '',
        productType: null,
        activeMethod: '',
      },
      records: [],
      queryConfigChannel: [],
      queryConfigChannelOptions: [],
      queryConfigStoreOptions: [],
      queryConfigProductTypeOptions: [
        { label: '青鸟', value: '10' },
        { label: '威萌', value: '20' },
        { label: '视诺星', value: '30' },
      ],
      queryConfigProductActivaMethodOptions: [
        { label: '门店', value: 'store' },
        { label: '消费者', value: 'customer' },
      ],
    };
  },
  created() {
    this.fetchRecords();
    this.fetchQueryStoreOptions('', 0);
    if (this.userType !== 'OUTLET') {
      this.fetchQueryChannelOptions();
    }
  },
  methods: {
    async fetchRecords() {
      const {
        page,
        limit,
        customerNickName,
        customerMobile,
        channelId,
        storeId,
        productNo,
        produceNo,
        sex,
        sgsNickName,
        startDate,
        endDate,
        startDates,
        endDates,
        patientType,
        minAge,
        maxAge,
        name,
        productType,
        activeMethod,
      } = this.queryData;
      if ((startDate && !endDate) || (!startDate && endDate)) {
        Message.error({
          content: '请选择开始时间范围',
          duration: 2,
          closable: true,
        });
        return;
      }
      if ((startDates && !endDates) || (!startDates && endDates)) {
        Message.error({
          content: '请选择结束时间范围',
          duration: 2,
          closable: true,
        });
        return;
      }
      if (minAge && !Number(minAge)) {
        Message.error({
          content: '最小年龄填写错误',
          duration: 2,
          closable: true,
        });
        return;
      }
      if (maxAge && !Number(maxAge)) {
        Message.error({
          content: '最大年龄填写错误',
          duration: 2,
          closable: true,
        });
        return;
      }
      if (Number(minAge) > Number(maxAge) && Number(minAge) && Number(maxAge)) {
        Message.error({
          content: '年龄范围填写错误',
          duration: 2,
          closable: true,
        });
        return;
      }
      const { count = 0, results = [] } = await TrainingRecordApi.getRecord(
        page,
        limit,
        channelId,
        storeId,
        productNo,
        produceNo,
        startDate,
        endDate,
        startDates,
        endDates,
        customerNickName,
        sgsNickName,
        sex,
        customerMobile,
        patientType,
        minAge,
        maxAge,
        name,
        productType,
        activeMethod,
      );
      this.records = results;
      this.$set(this.queryData, 'count', count);
    },
    /* 渠道列表 */
    async fetchQueryChannelOptions() {
      const options = await ChannelManageApi.getChannelsByChannelName();
      this.queryConfigChannel = options;
      this.queryConfigChannelOptions = DataTransferUtil.originTransfer2Options(options);
    },
    /* 门店列表 */
    async fetchQueryStoreOptions(name, channelId) {
      const options = await StoreManageApi.getStoresByStoreNameOrChannelId(
        name,
        channelId,
      );
      this.queryConfigStoreOptions = DataTransferUtil.originTransfer2Options(options);
    },
    /* 条件导出 */
    async onExport() {
      const {
        page,
        limit,
        customerNickName,
        customerMobile,
        channelId,
        storeId,
        productNo,
        produceNo,
        sex,
        sgsNickName,
        startDate,
        endDate,
        startDates,
        endDates,
        patientType,
        minAge,
        maxAge,
        name,
      } = this.queryData;
      if ((startDate && !endDate) || (!startDate && endDate)) {
        Message.error({
          content: '请选择开始时间范围',
          duration: 2,
          closable: true,
        });
        return;
      }
      if ((startDates && !endDates) || (!startDates && endDates)) {
        Message.error({
          content: '请选择结束时间范围',
          duration: 2,
          closable: true,
        });
        return;
      }
      if (minAge && !Number(minAge)) {
        Message.error({
          content: '最小年龄填写错误',
          duration: 2,
          closable: true,
        });
        return;
      }
      if (maxAge && !Number(maxAge)) {
        Message.error({
          content: '最大年龄填写错误',
          duration: 2,
          closable: true,
        });
        return;
      }
      if (Number(minAge) > Number(maxAge) && Number(minAge) && Number(maxAge)) {
        Message.error({
          content: '年龄范围填写错误',
          duration: 2,
          closable: true,
        });
        return;
      }
      const key = await TrainingRecordApi.export({
        page,
        limit,
        start_date: startDate,
        end_date: endDate,
        start_dates: startDates,
        end_dates: endDates,
        channel_id: channelId,
        store_id: storeId,
        product_no: productNo,
        produce_no: produceNo,
        customer_nick_name: customerNickName,
        sgs_nick_name: sgsNickName,
        sex,
        customer_mobile: customerMobile,
        patient_type: patientType,
        min_age: minAge,
        max_age: maxAge,
        train_process: name,
      });
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
    /* 全部导出 */
    async onExportAll() {
      const key = await TrainingRecordApi.export();
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
    /* 渠道选择 */
    onChange(value) {
      this.queryData.channelId = value;
      this.queryData.storeId = '';
      this.fetchQueryStoreOptions('', value);
    },
    getRowData(e, name, hardwareVersion) {
      let list = [];
      const chartRows = Object.keys(e);
      chartRows.forEach((item) => {
        if (item === name) {
          list = Object.values(e[name]);
        } else if (item === '融像训练') {
          const chartRow = Object.keys(e['融像训练']);
          chartRow.forEach((rowItem) => {
            if (rowItem === name) {
              list = Object.values(e['融像训练'][name]);
            }
          });
        }
      });

      if (hardwareVersion && hardwareVersion !== '1.0') {
        if (name === '调节幅度训练') {
          list.map((item) => {
            let maxDegrees = item['最高度数'];
            if (maxDegrees && (maxDegrees.indexOf('2.50') !== -1 || maxDegrees.indexOf('1.50') !== -1)) {
              maxDegrees = maxDegrees.replace(/2.50/, '2.00').replace(/1.50/, '1.00');
              item['最高度数'] = maxDegrees;
            }
            return item;
          });
        }
      }

      return list;
    },

    convertNewHardwareData(row, name, content) {
      let result = content;
      if (row && row.hardware_version && row.hardware_version !== '1.0') {
        if (name === '调节灵活度训练') {
          if (content && (content.indexOf('2.50') !== -1 || content.indexOf('1.50') !== -1)) {
            result = content.replace(/2.50/, '2.00').replace(/1.50/, '1.00');
          }
        }
      }
      return result;
    },
  },
};
</script>

<style lang="less" scoped>
.age-range > div,
.visual-range > div {
  display: flex;
  align-items: center;
  > div {
    margin-right: 4px;
  }
}
</style>
